@import "./variables.scss";

.FooterComponent {
  background-image: linear-gradient(
    0deg,
    $bg-start-point 0%,
    $bg-end-point 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    $bg-start-point 0%,
    $bg-end-point 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    $bg-start-point 0%,
    $bg-end-point 100%
  );
  background-image: -moz-linear-gradient(
    0deg,
    $bg-start-point 0%,
    $bg-end-point 100%
  );
  color: white;
  display: flex;
  .container {
    margin-top: 0rem 0rem 4rem 0rem;
    padding: 0rem 0rem 4rem 0rem;
  }
  .panes {
    justify-content: space-around;
  }
  p {
    font-size: 9pt;
  }
  .discussion-block {
    display: inline-flex;
    align-items: center;
    .d-header {
      font-size: 24px;
      font-family: $primary-font;
    }
    .social-links {
      margin-left: 8px;
      li {
        padding: 4px 0px;
      }
    }
  }
  .footer-logo {
    float: right;
    width: 8rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-right: 10%;
  }
  @media screen and (max-width: $mobile-view) {
    padding: 0rem;
    box-sizing: border-box;
    .panes {
      margin: 0;
      div.left-pane {
        padding-right: 0px;
      }
      div.right-pane {
        padding: 0px;
        margin: 0px 4px;
      }
    }
    p {
      font-size: 12px;
    }
    .discussion-block {
      .d-header {
        font-size: 12px;
      }
      .social-links {
        margin-left: 0px;
      }
      .social-links li a i {
        width: 23px;
        height: 23px;
        line-height: 23px;
        font-size: 18px;
      }
    }
    .footer-logo {
      float: right;
      width: 6rem;
      margin-right: 0;
    }
  }
}

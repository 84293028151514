@import "./variables.scss";

.modal-wrapper {
    background: #fff;
    color: #000;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    width: 60%;
    top: 50%;
    left: 50%;
    position: fixed;
    opacity: 1;
    transform: translate(-50%, -50%);
    overflow: scroll;
    padding-right: 20px;
}
.modal-header {
    font-size: 30px;
    padding: 18px 0px 0 20px;
}
.modal-body {
    padding: 18px 20px 0 20px;
}
.close-modal-btn {
    cursor: pointer;
    float: right;
}
.modal-overlay {
    position: fixed;
    transition: all 1.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}
@media screen and (max-width: $mobile-view) {
    .modal-wrapper {
        width: 95%;
        max-height: 60%;
        z-index: 1;
    }
    .modal-content-wrapper {
        text-align: left;
    }
    .modal-header {
        font-size: 18px;
    }
    .close-modal-btn {
        display: none;
    }
}
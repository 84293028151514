/**************************  END POINTS   ***************************/
/***************************  PALETTE   *****************************/
/**************************  TYPOGRAPHY *****************************/
/*
 * ArgentumSans
 */
@font-face {
  font-family: "ArgentumSansLight";
  src: url("../fonts/argentum-sans/argentum-sans.light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "ArgentumSansMedium";
  src: url("../fonts/argentum-sans/argentum-sans.medium.otf");
  font-weight: normal;
  font-style: normal; }

html,
body {
  font-family: "ArgentumSansLight";
  margin: 0px;
  overflow-x: hidden;
  height: 100%; }

body.no-scroll {
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .App.fixed header.Header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    background: #fff;
    margin: 0px; }
    .App.fixed header.Header img {
      max-width: 8rem;
      margin-top: 1rem; }
    .App.fixed header.Header p.tagline {
      display: none; } }

@media screen and (min-width: 769px) {
  .App.fixed .Header nav {
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
    margin: 0px;
    z-index: 1; }
    .App.fixed .Header nav .header-menu {
      margin: 0px; } }

/******************************************** LAYOUT **********************************/
.container {
  width: 80%;
  margin: 0rem auto;
  padding: 4rem 0rem;
  max-width: 1920px; }
  @media screen and (max-width: 768px) {
    .container {
      width: 100%;
      margin: 4rem auto;
      margin: 0rem auto;
      padding: 1rem 0rem; } }

.panes {
  width: 100%;
  display: inline-flex;
  margin: 0px -1rem; }
  .panes > div {
    padding: 0px 1rem; }
  .panes .pane2 {
    width: calc(100% / 2); }

/******************************************** INPUTS **********************************/
.input-wrapper {
  margin: 1rem 0rem; }
  .input-wrapper input[type="text"],
  .input-wrapper input[type="email"],
  .input-wrapper textarea {
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    line-height: 2rem;
    margin-right: 1rem;
    box-sizing: border-box;
    border: none;
    font-family: inherit;
    outline: none; }
    .input-wrapper input[type="text"].error,
    .input-wrapper input[type="email"].error,
    .input-wrapper textarea.error {
      border: 2px solid red; }
  .input-wrapper textarea {
    min-height: 10rem; }
  .input-wrapper .error-msg {
    font-size: 12px;
    color: red; }

.btn-container {
  margin: 1rem 0rem; }
  .btn-container .btn {
    background-color: white;
    padding: 0.5rem 1rem;
    height: 3rem;
    box-sizing: border-box;
    border-radius: 10px;
    min-width: 150px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    outline: none; }

.toaster {
  position: fixed;
  top: -5rem;
  background: #f58b12;
  right: 5rem;
  z-index: 1;
  padding: 1rem;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  opacity: 0; }
  .toaster.open {
    top: 5rem;
    opacity: 1; }

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

p {
  font-size: 1rem; }

.d-header {
  text-align: center;
  font-size: 18pt;
  font-family: "ArgentumSansMedium";
  margin: 16px 0px; }

@media screen and (max-width: 768px) {
  * {
    -webkit-overflow-scrolling: touch; }
  .d-header {
    font-size: 1rem;
    margin: 12px 0px; }
  p {
    font-size: 0.8rem; } }

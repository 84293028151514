/*
 * ArgentumSans
 */
@font-face {
  font-family: "ArgentumSansLight";
  src: url("../fonts/argentum-sans/argentum-sans.light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "ArgentumSansMedium";
  src: url("../fonts/argentum-sans/argentum-sans.medium.otf");
  font-weight: normal;
  font-style: normal; }

/**************************  END POINTS   ***************************/
/***************************  PALETTE   *****************************/
/**************************  TYPOGRAPHY *****************************/
.Header {
  text-align: center;
  margin: 3rem 0rem; }
  .Header img {
    max-width: 25%;
    margin: 0.5rem; }
  .Header .tagline {
    font-size: 15pt;
    max-width: 25%;
    margin: auto;
    min-width: 28rem;
    line-height: 20pt;
    margin-bottom: 5rem;
    color: #222338; }
  .Header nav {
    margin: 2rem 0rem;
    background-color: #f48311;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f48311+0,1f3b08+100;Olive+3D */
    background: -moz-linear-gradient(top, #f48311 50%, #f6a717 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f48311 50%, #f6a717 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f48311 50%, #f6a717 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f48311', endColorstr='#f6a717',GradientType=0 );
    /* IE6-9 */ }
    .Header nav ul.header-menu {
      max-height: 4rem;
      padding: 0px; }
      .Header nav ul.header-menu li {
        display: inline-block;
        list-style-type: none; }
        .Header nav ul.header-menu li a {
          text-decoration: none;
          padding: 1rem 2rem;
          line-height: 2rem;
          display: inline-block;
          color: #212529;
          font-family: "ArgentumSansMedium"; }
    .Header nav .discussion-block-container {
      display: none; }
  .Header .language-toggle {
    position: relative;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    float: right; }
    .Header .language-toggle .language-toggle-positioner {
      position: absolute;
      width: 200px; }
      .Header .language-toggle .language-toggle-positioner .language-toggle-header {
        text-align: right; }
        .Header .language-toggle .language-toggle-positioner .language-toggle-header .lang-title-text {
          padding-left: 20px; }
      .Header .language-toggle .language-toggle-positioner .language-toggle-header:hover {
        cursor: pointer; }
      .Header .language-toggle .language-toggle-positioner .language-list {
        background-color: #fff;
        padding: 10px;
        list-style: none;
        text-align: left;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); }
        .Header .language-toggle .language-toggle-positioner .language-list .language-list-item {
          padding: 5px; }
        .Header .language-toggle .language-toggle-positioner .language-list .language-list-item:hover {
          cursor: pointer; }
  .Header .lang-title-img {
    margin: 0px;
    position: relative;
    top: 6px; }
  .Header .lang-list-title {
    padding-left: 20px; }
  .Header i {
    margin-left: 20px; }

@media screen and (max-width: 768px) {
  .Header {
    margin: 1.5rem 0rem 0rem; }
    .Header .language-toggle {
      float: left; }
      .Header .language-toggle .language-toggle-positioner {
        width: 60px;
        top: 6rem;
        left: 20px;
        z-index: 1; }
        .Header .language-toggle .language-toggle-positioner .language-title-header {
          width: 60px; }
        .Header .language-toggle .language-toggle-positioner .language-list {
          padding: 10px 0;
          width: 60px; }
          .Header .language-toggle .language-toggle-positioner .language-list .language-list-item {
            text-align: center;
            padding: 0; }
        .Header .language-toggle .language-toggle-positioner .language-title-img {
          margin: 0;
          width: 60px; }
        .Header .language-toggle .language-toggle-positioner .lang-title-text, .Header .language-toggle .language-toggle-positioner .lang-list-title {
          display: none; }
      .Header .language-toggle .language-title-img {
        margin: 0; }
    .Header i {
      margin-left: 10px; }
    .Header .menu-toggler {
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      z-index: 10;
      cursor: pointer; }
      .Header .menu-toggler .line {
        height: 4px;
        width: 28px;
        background: black;
        border-radius: 2px;
        margin-bottom: 4px; }
    .Header img {
      max-width: calc(100% - 10rem);
      margin: auto;
      max-width: 50%; }
    .Header .tagline {
      font-size: 0.8rem;
      min-width: 50%;
      max-width: 58%;
      line-height: 1.2rem;
      margin-bottom: 0rem;
      min-width: 250px; }
    .Header .menu-toggler .line {
      transition: all 0.2s ease-in-out; }
    .Header .open.menu-toggler .line {
      background-color: #f47b0f; }
      .Header .open.menu-toggler .line:first-child {
        transform: rotate(45deg);
        top: 8px;
        position: relative; }
      .Header .open.menu-toggler .line:nth-child(2) {
        display: none; }
      .Header .open.menu-toggler .line:nth-child(3) {
        transform: rotate(-45deg); }
    .Header nav {
      position: absolute;
      top: 0px;
      margin: auto;
      height: calc(100vh - 4rem);
      width: 100%;
      left: -100vw;
      transition: all 0.2s ease-out;
      padding: 2rem 0rem;
      z-index: 9;
      background-image: linear-gradient(0deg, #222338 0%, #05050d 100%);
      background-image: -webkit-linear-gradient(0deg, #222338 0%, #05050d 100%);
      background-image: -ms-linear-gradient(0deg, #222338 0%, #05050d 100%);
      background-image: -moz-linear-gradient(0deg, #222338 0%, #05050d 100%);
      padding: 0px;
      height: 100vh; }
      .Header nav.open {
        left: 0; }
        .Header nav.open .discussion-block-container {
          display: block;
          position: absolute;
          bottom: 2rem;
          width: 100%;
          color: white; }
          .Header nav.open .discussion-block-container .discussion-block {
            width: 65%;
            border-top: 1px solid white;
            margin: auto;
            padding: 0.5rem 0rem 2rem;
            max-width: 320px; }
      .Header nav ul.header-menu {
        margin: 2rem 0rem; }
        .Header nav ul.header-menu li {
          display: block; }
          .Header nav ul.header-menu li a {
            font-size: 1.5rem;
            color: white;
            padding: 0.5rem; }
            .Header nav ul.header-menu li a:hover, .Header nav ul.header-menu li a:focus {
              color: #f47b0f; }
  .fixed .menu-toggler {
    top: 1.5rem; } }

@import "./variables.scss";

.AppDownloadBtn {
  display: inline-flex;
  margin: 0px;
  padding: 0px 6px;
  border-radius: 10px;
  border: none;
  background: white;
  align-items: center;
  color: $primary-color;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  img {
    max-height: 4rem;
  }
  .super-text {
    font-size: 12px;
  }
  .app-name {
    font-size: 24px;
    .version {
      font-size: 10px;
      margin: 8px;
    }
  }
}

.sub-text {
  font-size: 8pt;
  margin: 0.1rem;
  position: relative;
  a {
    color: white;
  }
}
@media screen and (max-width: $mobile-view) {
  .AppDownloadBtn {
    img {
      max-height: 3rem;
    }
    .sub-text {
      font-size: 0.65rem;
      margin: 0.2rem;
      position: relative;
    }
  }
}

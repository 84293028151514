@import "./variables.scss";

.CarouselComponent {
  color: $primary-color;
  .d-header {
    margin-bottom: 2rem;
  }
  .d-header + div > ul {
    top: 100%;
    // To alter color of indicators
    // button:nth-child(1) > i {
    //   background-color: #f6a418;
    // }
    // button:not(:focus) > i {
    //   background-color: #E2E2E2;
    // }
    // button:focus > i {
    //   background-color: #f6a418;
    // }
  }
  div.carousel-slide {
    height: 27rem;
    background-color: #e2e2e2;
    a {
      color: $primary-color;
      text-decoration: none;
    }
    a .carousel-title:hover {
      text-decoration: underline;
    }
    p.carousel-title {
      padding: 0 1rem;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p.carousel-summary {
      font-size: 14px;
      padding: 0 1rem;
      max-height: 8rem;
      overflow: hidden;
    }
  }
}
@media screen and (max-width: $mobile-view) {
  .CarouselComponent {
    &.container {
      margin-bottom: 4rem;
    }
    div.carousel-slide {
      height: 27rem;
      width: 80%;
      margin: 0 auto;
      .carousel-img {
        max-height: none;
      }
    }
    div.carousel-slide p {
      padding: 0 2rem;
    }
  }
}

@media screen and (min-width: $desktop-view-lg) {
  .CarouselComponent {
    .carousel-img {
      max-height: 50%;
    }
  }
}

.CarouselComponent.container {
  padding: 4rem 0 8rem 0rem;
}

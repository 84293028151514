/*
 * ArgentumSans
 */

@font-face {
  font-family: "ArgentumSansLight";
  src: url("../fonts/argentum-sans/argentum-sans.light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArgentumSansMedium";
  src: url("../fonts/argentum-sans/argentum-sans.medium.otf");
  font-weight: normal;
  font-style: normal;
}

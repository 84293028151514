/**************************  END POINTS   ***************************/
$mobile-view: 768px;
$desktop-view-lg: 1700px;
$container-max-width: 1920px;
/***************************  PALETTE   *****************************/
$orange: #f47b0f;

$primary-color: #222338;
$secondary-color: $orange;
$primary-text-color: #212529;
$bg-start-point: $primary-color;
$bg-end-point: #05050d;
/**************************  TYPOGRAPHY *****************************/
$primary-font: "ArgentumSansLight";
$primary-font-medium: "ArgentumSansMedium";

@import "./variables.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.css";

.FeedbackComponent {
  .wrapper {
    background-color: $primary-color;
    color: white;
    padding: 3rem 8%;
    .panes {
      p {
        font-size: 1rem;
        max-width: 90%;
      }
    }
    form[name="contactUsForm"] {
      max-width: 500px;
      margin-left: auto;
    }
  }
  .d-header {
    text-align: center;
  }
  .input-wrapper,
  .btn-container {
    text-align: right;
  }
  .input-wrapper {
    font-size: 8pt;
    .open-tc-modal {
      text-decoration: underline;
    }
    .open-tc-modal:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  @media screen and (max-width: $mobile-view) {
    .wrapper {
      text-align: center;
      .panes {
        p {
          max-width: 100%;
        }
      }
      form[name="contactUsForm"] {
        max-width: 100%;
        margin-left: auto;
        margin-top: 3rem;
      }
    }
    .panes {
      display: block !important;
    }
    .pane2 {
      width: 100% !important;
    }
  }
}

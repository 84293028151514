@import "./variables.scss";
.TeamComponent {
  text-align: center;
  .d-header {
    color: $primary-color;
  }
  .teamList {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    flex-wrap: wrap;
    margin: 0 10%;
    li {
      list-style-type: none;
      width: calc(100% / 3);
      a {
        text-decoration: none;
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 0px 12%;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-view) {
  .TeamComponent {
    &.container {
      padding: 2rem 0rem;
    }
    .teamList {
      li {
        width: calc(100% / 2);
        a {
          padding: 0px 4%;
        }
      }
    }
  }
}

@import "./variables.scss";

.BoardComponent {
  text-align: center;
  display: flex;
  .overlay {
    z-index: 0;
    padding: 5rem 16%;
    color: white;
  }
  .box {
    position: relative;

    background-image: linear-gradient(
      0deg,
      $bg-start-point 0%,
      $bg-end-point 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      $bg-start-point 0%,
      $bg-end-point 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      $bg-start-point 0%,
      $bg-end-point 100%
    );
    background-image: -moz-linear-gradient(
      0deg,
      $bg-start-point 0%,
      $bg-end-point 100%
    );
    &::before {
      content: "";
      background-image: linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -webkit-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -ms-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -moz-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -webkit-transform: rotate(-3deg);
    }
    &::after {
      content: "";
      background-image: linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -webkit-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -ms-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      background-image: -moz-linear-gradient(
        135deg,
        rgba(34, 35, 56, 0.7) 0%,
        rgba(5, 5, 13, 0.7) 100%
      );
      transform: rotate(-6deg);
      -moz-transform: rotate(-6deg);
      -ms-transform: rotate(-6deg);
      -o-transform: rotate(-6deg);
      -webkit-transform: rotate(-6deg);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .download-link-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 28rem;
    margin: 3rem 0rem;
  }
  .download-link {
    width: 50%;
    text-align: center;
    margin: auto;
  }
  .discussion-block {
    border-top: 1px solid;
    max-width: 510px;
    margin: auto;
    .d-header {
      font-size: 18pt;
      font-family: $primary-font;
      margin: 16px 0px;
    }
    li {
      a {
        color: #fff;
        background: transparent;
        &:hover {
          i {
            color: $primary-color;
            transform: scale(1.2);
          }
        }
      }
      a > img {
        width: 50px;
        margin: 0rem 1rem;
      }
    }
  }
}

@media screen and (max-width: $mobile-view) {
  .BoardComponent {
    padding: 3rem 0rem;
    .container {
      width: 100%;
      padding: 0px;
    }
    .overlay {
      padding: 1rem 8%;
    }
    .box {
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 175%;
        height: 100%;
        left: -50%;
        z-index: -1;
        top: 0px;
      }
    }
  }
}

.discussion-block {
  .social-links {
    padding: 0px;
    li {
      list-style-type: none;
      display: inline-block;
      a {
        // color: white;
        transition: all 0.5s ease-in-out;
        // text-rendering: optimizeLegibility;
        // display: block;
        margin: 1rem 0.5rem;
        i {
          background-color: #fff;
          color: $primary-color;
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          transition: all 0.5s;
          text-rendering: optimizeLegibility;
        }
        &:hover {
          color: $primary-color;
          i {
            transform: scale(1.2);
            background-color: transparent;
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-view) {
  .BoardComponent {
    .download-link,
    .discussion-block {
      max-width: 70%;
      margin: auto;
      width: 400px;
      .d-header {
        font-size: 1rem;
        margin: 12px 0px;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .BoardComponent {
    .download-link-container {
      flex-direction: column;
    }
  }
}
